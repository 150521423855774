<template>
  <div class="main-panel large" v-loading="isGetProcess">
    <h3 class="panel-title">流程设置</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <my-process :list="list" mode="edit" @add="handleAdd" @edit="handleEdit" />
  </div>
</template>

<script>
import MyProcess from "@/components/MyProcess/MyProcess";
// import template from "@/components/MyProcess/template";

export default {
  components: {MyProcess},

  data() {
    return {
      list: [],
      delList: [],
      isGetProcess: false,
      isLoading: false
    }
  },

  mounted() {
    this.getProcess()
  },

  methods: {
    async getProcess() {
      this.isGetProcess = true
      const res = await this.$api.Base.Process.getList().catch(() => {})
      this.isGetProcess = false
      // console.log(res)
      if (res?.code === 200) {
        this.list = res.data.map(processItem => ({
          id: processItem.id,
          name: processItem.name,
          isRequire: Boolean(processItem.flag),
          isShowInProcess: Boolean(processItem.isShow),
          child: processItem.child.map(childProcess => ({...childProcess, delflag: 1})),
          delflag: 1
        }))
      } else {
        this.$message.error("获取流程失败")
      }
      // if (!this.list.length) {
      //   this.list = template.parent
      // }
    },
    handleAdd(data) {
      this.list.push(data)
    },
    handleEdit(data) {
      // console.log(data)
      if (data.delflag) {
        this.list.splice(data.index, 1, data)
      } else {
        if (data.id) {
          this.delList.push({...this.list[data.index], delflag: 0})
        }
        this.list.splice(data.index, 1)
      }
    },
    async save() {
      this.isLoading = true
      const sendData = {
        parent: [...this.list.concat(this.delList).map((processItem, processIndex) => ({
          id: processItem.id || null,
          name: processItem.name,
          flag: Number(processItem.isRequire),
          isShow: Number(processItem.isShowInProcess),
          child: processItem.child,
          delflag: processItem.delflag,
          sort: processIndex + 1
        }))]
      }
      // console.log(sendData)
      const res = await this.$api.Base.Process.update(sendData).catch(() => {})
      this.isLoading = false

      if (res?.code === 200) {
        this.$message.success("保存成功")
        this.list = []
        this.delList = []
        await this.getProcess()
      } else {
        this.$message.error("保存失败")
      }
    }
  }
}
</script>
